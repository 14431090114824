<template>
  <div class="container">
    <label for="orderStatussen" class="form-label mb-1">Filter</label>
    <div v-for="status in sortedOrderstatussen" :key="status.id" class="form-check form-switch ">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status[`status_${status.id}`]" :id="`status_${status.id}`" key="" >
      <label class="form-check-label label-text" for="flexCheckDefault" :title="status.label">
        &nbsp;<i class="bi bi-circle-fill close" :class="getColor(status.id)"></i>&nbsp;{{ status.label }}
      </label>
    </div>
    <div class="dropdown mt-4">
      <button class="btn btn-primary dropdown-toggle" type="button" @click="toggleDropdown()" aria-expanded="false">Selecteer klanten</button>
      <div v-if="dropdownOpen" class=" px-2">
        <form class="px-4 py-3">
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.sabic" id="dropdownCheck1">
              <label class="form-check-label ms-2" for="dropdownCheck">Sabic</label>
            </div>
              <div class="form-check mb-2">
                <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.tata" id="dropdownCheck2">
                <label class="form-check-label ms-2" for="dropdownCheck">Tata</label>
              </div>
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.esso" id="dropdownCheck3">
              <label class="form-check-label ms-2" for="dropdownCheck">Esso</label>
            </div>
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.huntsman" id="dropdownCheck4">
              <label class="form-check-label ms-2" for="dropdownCheck">Huntsman</label>
            </div>
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.cabot" id="dropdownCheck5">
              <label class="form-check-label ms-2" for="dropdownCheck">Cabot</label>
            </div>
        </form>
      </div>
    </div>
    <div>
      <p class="fs-6 text-start text-secondary"><i>{{ selectedCustomer }}</i></p>
    </div>
    <input type="text" class="rounded-input" v-model="filter.userAgentFilter" @input="filterStatusChanged()" placeholder="Filter op User Agent">
  </div>
</template>

<script>
import Utils from "../utils/Utils"
import { watch } from 'vue'
import ServiceFactory from '@/services/ServiceFactory'
import localDB from '../utils/localDB';
var capitalize = require('lodash/capitalize')
export default {
  name: 'FilterOrder',
  props: {
    orderstatussen: Array 
  },
  emits: ['filterStatusChanged'],
  data() {
    return {
      orderService: null,
      filter: {
        status: {
          verwerkt:false, failed:false, open:false, dubbel:false, onjuist:false, handmatig:false, zonderRegels:false, unit4: false
        },
        customer: {
          sabic:false, tata:false, esso:false, huntsman:false, cabot:false
        },
        userAgentFilter: ''
      },
      selectedCustomer: "Geen selectie",
      userAgentFilter: '',
      dropdownOpen: false,
      sortedOrderstatussen: [],
    }
  },
  watch: {
    orderstatussen(newOrderstatussen) {
      if (newOrderstatussen) {
        this.sortedOrderstatussen = [...newOrderstatussen].sort((a, b) => a.id - b.id);
      } else {
        this.sortedOrderstatussen = [];
      }
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status)
    },
    filterStatusChanged() {
      this.updateSelectedCustomerString()
      this.$emit('filterStatusChanged', this.filter)
    },
    updateSelectedCustomerString(){
      this.selectedCustomer = ""
      for(let customer in this.filter.customer) {
        let customerSelected = this.filter.customer[customer]
        if(customerSelected){
          this.selectedCustomer += `${capitalize(customer)}, `
        }
      }
      if(this.selectedCustomer.length > 0) {
        this.selectedCustomer = this.selectedCustomer.slice(0, -2)
      }else{
        this.selectedCustomer = "Geen selectie"
      }
      
    },
    toggleDropdown(){
      this.dropdownOpen = !this.dropdownOpen
    }
  },
  async mounted() {
    this.orderService = ServiceFactory.getService('OrderService')
    this.orderService.addEventListener('ordersLoaded', () => {
      this.filterStatusChanged()
    });
    
    const storedFilter = await localDB.getItem('orderFilter');
    if (storedFilter) {
      this.filter = JSON.parse(storedFilter);
      this.updateSelectedCustomerString()
    }
    
    watch(() => this.filter, async (newValue) => {
      await localDB.setItem('orderFilter', JSON.stringify(newValue));
    }, { deep: true });
  }
  
}
</script>

<style scoped>
.form-check-input {
  position: relative;
  top: -2px;
}
.form-check {
  display: flex;
  align-items: center;
}
.label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;  
  display: inline-block;
}
.rounded-input {
  border-radius: 8px; 
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
</style>