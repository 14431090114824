//import BBOrderService from './BBOrderService';
import OrderService from './OrderService';
import InvoiceService from './InvoiceService';
import BBOrderBevestigingService from './BBOrderBevestigingService';
import OAuth2AzureService from './OAuth2AzureService';
import WebSocketService from './WebSocketService';
import CustomerService from './CustomerService';
class ServiceFactory {
  orderService = null;
  invoiceService = null;
  orderBevestigingService = null;
  oAuth2AzureService = null;
  webSocketService = null;
  customerService = null;
  static getService(serviceType) {
    switch (serviceType) {
      case 'OrderService':
        if (!this.orderService) {
          this.orderService = new OrderService();
        }
        return this.orderService
      case 'InvoiceService':
        if (!this.invoiceService) {
          this.invoiceService = new InvoiceService();
        }
        return this.invoiceService
      case 'WebSocketService':
        if (!this.webSocketService) {
          this.webSocketService = new WebSocketService();
        }
        return this.webSocketService
      case 'OrderBevestigingService':
        if (!this.orderBevestigingService) {
          this.orderBevestigingService = new BBOrderBevestigingService();
        }
        return this.orderBevestigingService
      case 'OAuth2AzureService':
        if (!this.oAuth2AzureService) {
          this.oAuth2AzureService = new OAuth2AzureService();
        }
        return this.oAuth2AzureService
      case 'CustomerService':
        if (!this.customerService) {
          this.customerService = new CustomerService();
        }
        return this.customerService
      default:
        throw new Error(`Unknown service type: ${serviceType}`);
    }
  }
}

export default ServiceFactory;
